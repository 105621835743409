import { useContext, useEffect } from 'react'

/*Suppression du UseState et UseEffect au profit du Hook UseFetch
  import { useState, useEffect } from 'react'
*/
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../utils/style/colors'
import { TopContainer, TopTextSection, TopTitle, TopPresentation, TopComplement, TopImageSection, TopImage } from '../../utils/style/Atoms'
import { Loader, StyledBar, StyledButton } from '../../utils/style/Atoms'
import { SurveyContext } from '../../utils/context'

// Importation du Hook useFetch
import { useFetch, useTheme, usePageTitleContext } from '../../utils/hooks'

import { Helmet, HelmetProvider } from 'react-helmet-async';


const PageWrapper = styled.main`
    margin: auto;
    max-width: 2400px;
    @media screen and (min-width: 2399px) {
        box-shadow: rgb(226, 227, 233) 2px -2px 6px;
    }
    ${TopComplement} {
        margin-bottom: 0;
    }
    ${TopImageSection} {
        align-items: center;
        @media screen and (max-width: 480px) {
            display: none;
        }
    }
    ${TopContainer} {
        @media screen and (min-width: 980px) {
            padding: 40px;
            min-height: 0;
        }
        @media screen and (max-width: 980px) {
            padding: 30px 20px 0 20px;
            min-height: 0;
        }
        @media screen and (max-width: 480px) {
            padding: 20px;
        }
    }
    ${TopImage} {
        @media screen and (max-width: 1040px) {
            max-width: 360px;
        }
        @media screen and (max-width: 980px) {
            margin: auto;
        }
    }
`

const SurveyContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    box-shadow: 2px -2px 6px ${colors.shadow};
    @media screen and (min-width: 480px) {
        padding: 50px 0;
    }
    ${Loader} {
        margin: 20px auto;
    }
`

const QuestionTitle = styled.h2`
    text-decoration: underline;
    color: ${colors.black};
    transition: all 200ms;
`

const QuestionContent = styled.p`
    margin: 30px;
    color: ${colors.black};
    transition: all 200ms;
    @media screen and (max-width: 480px) {
        text-align: justify;
        margin: 15px 30px;
    }
`

const LinkWrapper = styled.section`
    padding-top: 30px;
    transition: all 200ms;
    @media screen and (max-width: 480px) {
        padding-top: 15px;
    }
    & a {
        color: ${({ theme }) => (theme === 'light' ? colors.black : colors.white)};
        &:hover {
            transform: scale(1.06);
        }
    }
    & a:first-of-type {
        margin-right: 20px;
    }
    ${StyledButton} {
        width: 170px;
        padding: 5px 0;
        border-radius: 20px;
        font-family: Impact;
        font-size: 18px;
        @media screen and (max-width: 480px) {
            width: 140px;
            font-size: 16px;
        }
    }
`

const LinkBox = styled.span`
    text-align: center;
    color: ${colors.black};
    cursor: pointer;
    transition: all 200ms;
    &:first-of-type {
        margin-right: 40px;
        @media screen and (max-width: 480px) {
            margin-right: 10px;
        }
    }
    &:last-of-type {
        margin-left: 50px;
        @media screen and (max-width: 480px) {
            margin-left: 10px;
        }
    }
`

const ReplyBox = styled.button`
    border: none;
    height: 50px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: ${colors.black};
    transition: all 200ms;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: ${(props) =>
        props.$isSelected ? `3px 2px 4px ${props.theme === 'light' ? colors.orange : colors.primary}, -1px 0px 4px ${props.theme === 'light' ? colors.orange : colors.primary}` : `1px 1px 2px ${colors.secondary}`};
    &:first-child {
        margin-right: 15px;
    }
    &:last-of-type {
        margin-left: 15px;
    }
    &:hover {
        box-shadow: ${(props) =>
        props.$isSelected ? `3px 2px 4px ${props.theme === 'light' ? colors.orange : colors.primary}, -1px 0px 4px ${props.theme === 'light' ? colors.orange : colors.primary}` : `4px 2px 4px ${props.theme === 'light' ? colors.celadon : colors.secondary}, -1px 0px 4px ${props.theme === 'light' ? colors.celadon : colors.secondary}`};
    }
`

const ReplyWrapper = styled.section`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  @media screen and (max-width: 480px) {
    padding-top: 0px;
  }
`

const Arrow = styled.img`
    width: 40px;
    height: 40px;
    margin: 0 8px;
`

function Survey() {

    const { title, setTitle } = usePageTitleContext()
    
    useEffect(() => {
        setTitle('Votre projet');
    }, [title, setTitle]);

    // Set meta tags when Home Page loads
    const usePageMeta = (title, description) =>{
        const defaultTitle = "Projet digital personnalisé | Becom'in | Caen | Calvados";
        const defaultDesc = "Entreprise spécialisée en développement web et mobile, Becom'in vous aide à savoir quel type de site ou application correspond à vos besoins.";
    
        useEffect(() => {
            // Title meta tag
            document.title = title || defaultTitle;
            // Description meta tag
            document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
        }, [defaultTitle, title, defaultDesc, description]);
    };
    
    usePageMeta("Projet digital personnalisé | Becom'in | Caen | Calvados", "Entreprise spécialisée en développement web et mobile, Becom'in vous aide à savoir quel type de site ou application correspond à vos besoins.");

    const { questionNumber } = useParams()
    const questionNumberInt = parseInt(questionNumber)
    const prevQuestionNumber = questionNumberInt === 1 ? 1 : questionNumberInt - 1
    const nextQuestionNumber = questionNumberInt + 1
    const { theme } = useTheme()
    const { saveAnswers, answers } = useContext(SurveyContext)

    function saveReply(answer) {
        saveAnswers({ [questionNumber]: answer })
    }

    const { data, isLoading, error } = useFetch(`https://www.becom-in.fr/api/survey`)
    const surveyData = data?.surveyData

    if (error) {
        return <span>Oups il y a eu un problème avec le chargement du questionnaire</span>
    }

    return (
        <HelmetProvider>
            <PageWrapper id='main-content'>
                <Helmet>
                    <link rel="canonical" href="https://www.becom-in.fr//votre-projet/1" />
                </Helmet>
                <TopContainer >
                    <TopTextSection theme={theme}>
                        <TopTitle theme={theme}>
                            DÉFINISSEZ VOTRE PROJET
                        </TopTitle>
                        <StyledBar theme={theme}></StyledBar>
                        <TopPresentation theme={theme}>
                            Répondez à 7 questions simples et découvrez immédiatement les prestations qui correspondent à vos besoins.
                        </TopPresentation>
                        <TopComplement theme={theme}>
                            Ce test est confidentiel et vous serez la seule personne à en connaître le résultat. Même pas nous !
                        </TopComplement>
                    </TopTextSection>
                    <TopImageSection theme={theme}>
                        <TopImage theme={theme} src={theme === 'light' ? `https://www.becom-in.fr/api/images/project-light.webp` : `https://www.becom-in.fr/api/images/project-dark.webp` } alt='Client Project Idea definition' />
                    </TopImageSection>
                </TopContainer>
                <SurveyContainer>
                    <QuestionTitle theme={theme}>
                        Question {questionNumber} / 7
                    </QuestionTitle>
                    {isLoading ? (
                        <Loader />
                    ) : (
                    <QuestionContent theme={theme}>
                        {surveyData && surveyData[questionNumber]}
                    </QuestionContent>
                    )}
                    {answers && (
                        <ReplyWrapper>
                            <ReplyBox
                                onClick={() => saveReply(true)}
                                $isSelected={answers[questionNumber] === true}
                                theme={theme}
                                title='Répondre "oui"'
                            >
                            OUI
                            </ReplyBox>
                            <ReplyBox
                                onClick={() => saveReply(false)}
                                $isSelected={answers[questionNumber] === false}
                                theme={theme}
                                title='Répondre "non"'
                            >
                            NON
                            </ReplyBox>
                        </ReplyWrapper>
                    )}

                    <LinkWrapper theme={theme}>

                        {/*Display previous button if there's a previous question*/}
                        {surveyData && surveyData[questionNumberInt - 1] ? (
                            <Link to={`/votre-projet/${prevQuestionNumber}`} title='Question précédente' theme={theme}>
                                <Arrow theme={theme} src={theme === 'light' ? `https://www.becom-in.fr/api/images/previous-light.webp` : `https://www.becom-in.fr/api/images/previous-dark.webp`} alt='Previous Question Arrow - Survey'/>
                                <LinkBox theme={theme}> Retour </LinkBox>
                            </Link> 
                        ): ( 
                            null
                        )}

                        {/*Display next button if there's a next question*/}
                        {surveyData && surveyData[questionNumberInt + 1] ? (
                            <Link to={`/votre-projet/${nextQuestionNumber}`} title='Question suivante' theme={theme}>
                                <LinkBox theme={theme}>Suivante </LinkBox>
                                <Arrow theme={theme} src={theme === 'light' ? `https://www.becom-in.fr/api/images/next-light.webp` : `https://www.becom-in.fr/api/images/next-dark.webp` } alt='Next Question Arrow - Survey'/>
                            </Link> 

                        ) : (

                            null
                        )}

                        {/*Display results button if last answer is selected*/}
                        {surveyData && ((answers[7] === false) || (answers[7] === true)) ? (
                            <Link to="/resultats" title='Voir les résultats'>
                                <StyledButton theme={theme}>Voir les résultats</StyledButton>
                            </Link> 
                        ) : (
                            null
                        )}
                    </LinkWrapper>
                </SurveyContainer>
            </PageWrapper>
        </HelmetProvider>
    )
}

export default Survey
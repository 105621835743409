import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../utils/style/colors'
import { useTheme } from '../../utils/hooks'

// Stylisation des Eléments
const CardTitle = styled.h3`
    color: ${(colors.white)};
    font-size: 22px;
    transition: all 200ms;
    @media screen and (min-width: 481px) and (max-width: 660px) {
        font-size: 18px;
    }
    @media screen and (max-width: 480px) {
        font-size: 15px;
        margin-top: 10px;
    }
`

const CardImage = styled.img`
    height: 80px;
    width: 80px;
    @media screen and (max-width: 660px) {
        height: 60px;
        width: 60px;
    }
`


const CardWrapper = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 15px 12px;
    width: 240px;
    height: 240px;
    transition: all 200ms;
    align-items: center;
    &:hover {
        animation-play-state: inherit;
        transform: scale(1.04);
        box-shadow: inset 4px 3px 4px ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary )}, inset -4px -3px 4px ${({ theme }) => (theme === 'light' ? colors.orange : colors.primary )}, inset 1px 1px 1px ${({ theme }) => (theme === 'light' ? colors.celadon : colors.backgroundDark )}, inset -1px -1px 1px ${({ theme }) => (theme === 'light' ? colors.celadon : colors.backgroundDark )};
    }
    @media screen and (max-width: 1110px) and (min-width: 481px) {
        height: 200px;
    }
    @media screen (min-width: 481px) and (max-width: 980px) {
        width: 200px;
    }
    @media screen and (max-width: 480px) {
        width: 130px;
        height: 120px;
    }
`

function Card({ title, picture }) {
    const { theme } = useTheme()
 
    return (
        <CardWrapper theme={theme} >
            <CardImage src={theme === 'light' ? picture[0] : picture[1] } alt={"Pictogramme prestation " + title + " Becom'in"} />
            <CardTitle > {title} </CardTitle>
        </CardWrapper>
    )
}

// Typage des données
Card.propTypes = {
    title: PropTypes.string.isRequired,
    picture: PropTypes.array.isRequired,
    theme: PropTypes.string.isRequired,
}

// Nommage par défaut des données
Card.defaultProps = {
    title: '',
    picture: "",
}

export default Card
// ACCESSIBILITY OPTIMIZATION - Component used for keybord navigation to skip all nav links & jump to main content
import { SkipNavLinks } from '../../utils/style/Atoms'
import { useTheme } from '../../utils/hooks'

function SkipLinks() {

    const { theme } = useTheme()

    return (
        <SkipNavLinks href='#main-content' theme={theme}>Aller au contenu principal</SkipNavLinks>
    )
}

export default SkipLinks
const colors = {
  primary: '#FCDC12',
  secondary: '#8186A0', // Grey
  backgroundLight: '#ffffff',
  backgroundDark: '#2c2933',
  backgroundGold: 'Gold',
  dark: '#2F2E41',
  turquoise: 'Turquoise',
  grey: '#696969',
  black: '#000000',
  white: '#ffffff',
  shadow: '#e2e3e9',
  orange: 'DarkOrange',
  blue: '#3933ff',
  celadon: '#004953',
}

export default colors
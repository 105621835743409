import styled from 'styled-components'
import React, { useEffect, Suspense, lazy } from 'react'
import { usePageTitleContext } from '../../utils/hooks'
import { Loader } from '../../utils/style/Atoms'

import { Helmet, HelmetProvider } from 'react-helmet-async'

import HomePrestations from '../../components/Home/HomePrestations'
import HomeExpertise from '../../components/Home/HomeExpertise'


const PageWrapper = styled.main`
    margin: auto;
    max-width: 2400px;
    @media screen and (min-width: 2399px) {
        box-shadow: rgb(226, 227, 233) 2px -2px 6px;
    }
`


const TestContainer = lazy(() =>
  import(
    /* webpackChunkName: "testContainer" */ /* webpackPreload: true */ '../../components/TestContainer'
  )
);


function Home() {
    const { title, setTitle } = usePageTitleContext()
    
    // Set header page
    useEffect(() => {
        setTitle('Bienvenue chez Becom\'in');
    }, [title, setTitle]);

    // Set meta tags when Home Page loads
    const usePageMeta = (title, description) =>{
        const defaultTitle = "Becom'in | Développement web et mobile à Caen";
        const defaultDesc = "Agence web située près de Caen en Normandie, Becom'in vous accompagne dans la création de votre site internet ou de votre application mobile (Android / IOS).";
        
    
        useEffect(() => {
            // Title meta tag
            document.title = title || defaultTitle;
            // Description meta tag
            document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);

        }, [defaultTitle, title, defaultDesc, description]);
    };

    usePageMeta("Becom'in | Développement web et mobile à Caen", "Agence web située près de Caen en Normandie, Becom'in vous accompagne dans la création de votre site internet ou de votre application mobile (Android / IOS).");
   

    

    return (
        <React.Fragment>
            <HelmetProvider>
                <PageWrapper id='main-content'>
                    <Helmet>
                        <link rel="canonical" href="https://www.becom-in.fr" />
                    </Helmet>

                    <HomePrestations />
                    <HomeExpertise />
                    
                    <Suspense fallback={<main style={{display:'flex', textAlign:'center', justifyContent:'center', height:'100vh', alignItems:'center'}}><Loader /></main>}></Suspense>
                        <TestContainer />
                    <Suspense />
                
                </PageWrapper>
            </HelmetProvider>
        </React.Fragment>
        
    )
}

export default Home
import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom/client";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  const root = ReactDOM.hydrateRoot(document.getElementById("root"), <App />);
  console.log(root);

} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

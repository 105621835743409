import styled from 'styled-components'
import { StyledButton, StyledBar } from '../../../utils/style/Atoms'
import colors from '../../../utils/style/colors'
import { useTheme } from '../../../utils/hooks'
import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import backgroundDesktop from '../../../../src/assets/background.webp'
import backgroundMobile from '../../../../src/assets/backgroundMob4.webp'

import circleGold from '../../../../src/assets/circleGOLD.svg'
import circleOrange from '../../../../src/assets/circleORANGE.svg'

import sitesLight from '../../../../src/assets/sites-light-white-circle.webp'
import sitesDark from '../../../../src/assets/sites-dark-white-circle.webp'
import seoLight from '../../../../src/assets/seo-light-white-circle.webp'
import seoDark from '../../../../src/assets/seo-dark-white-circle.webp'
import demandLight from '../../../../src/assets/dev-light-white-circle.webp'
import demandDark from '../../../../src/assets/dev-dark-white-circle.webp'
import appLight from '../../../../src/assets/app-light-white-circle.webp'
import appDark from '../../../../src/assets/app-dark-white-circle.webp'

import Card from '../../Card'


const PrestationsSection = styled.section`
    background-color: ${({ theme }) => (theme === 'light' ? colors.celadon : colors.backgroundDark)};
    z-index: -1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
    transition: all 200ms;
    ${StyledButton} {
        max-width: 260px;
        transition: all 200ms;
        box-shadow: ${({ theme }) => (theme === 'light' ? `4px 4px 8px ${colors.black}` : `8px 8px 12px ${colors.black}` )};
        &:hover {
            box-shadow: ${({ theme }) => (theme === 'light' ? `6px 6px 10px ${colors.black}` : `8px 8px 12px ${colors.black}` )};
        }
        @media screen and (min-width: 1140px) {
            margin: 15px 0;
        }
        @media screen and (max-width: 1140px) {
            margin: 0 auto;
        }
        @media screen and (max-width: 980px) {
            display: flex;
            justify-content: center;
            margin-top : 30px;
            margin-bottom: 20px;
        }
        @media screen and (max-width: 480px) {
            margin: 20px auto 0;
        }
    }
    ${StyledBar} {
        @media screen and (max-width: 1140px) {
            text-align: center;
            margin: 20px auto;
        }
    }
    @media screen and (max-width: 980px) {
        text-align: center;
        margin: auto;
    }
    @media screen and (min-width: 980px) {
        flex-direction: row;
        text-align: left;
    }
`

const ContentSection = styled.section`
    display: flex;
    flex-direction: column;
    align-content: center;
    z-index: 1;
    flex-wrap: wrap;
    transition: all 200ms;
    text-align: justify;
    @media screen and (min-width: 1140px) {
        max-width: 50%;
        min-width: 40%;
    }
    @media screen and (min-width: 980px) and (max-width: 1140px) {
        min-width: 30%;
    }
    @media screen and (max-width: 980px) {
        display: block;
        padding: 20px 0;
        width: 85%;
    }
`

const EntityTitle = styled.h2`
    line-height: 1;
    color: ${(colors.white)};
    transition: all 200ms;
    @media screen and (max-width: 1140px) {
        text-align: center;
    }
`

const EntityText = styled.p`
    color: ${(colors.white)};
    transition: all 200ms;
    text-wrap: wrap;
    max-width: 420px;
    margin-top: 10px;
    @media screen and (max-width: 1140px) {
        margin: 20px auto 10px;
        max-width: 85%;
    }
`

const PrestationsList = styled.ul`
    &:after {
        box-sizing: none;
    }
    &:before {
        box-sizing: none;
    }
    
`

const PrestationExample = styled.li`
    color: ${(colors.white)};
    transition: all 200ms;
    display: flex;
    text-wrap: wrap;
    max-width: 420px;
    margin-top: 10px;
    list-style-type: none;
    padding-left: 10px;
    font-weight: bold;
    @media screen and (max-width: 1140px) {
        margin: 20px auto 10px;
        max-width: 85%;
    }
    @media screen and (max-width: 980px) {
        margin: 10px auto 10px;
        &:first-child {
            margin: 15px auto 0px;
        }
    }
    @media screen and (max-width: 480px) {
        margin: 5px auto 5px;
    }
    &:before {
        content:'';
        padding: 0 0 0 20px;
        background-image: url(${({ theme }) => (theme === 'dark' ? circleGold : circleOrange)});
        position: relative;
        background-repeat: no-repeat;
        width: 8px;
        height: 8px;
        top: 10px;
    }
`

const PicturePrestationsSection = styled.section`
    z-index: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    background: linear-gradient(0deg, rgba(0,0,0,0.45), rgba(0,0,0,0.45)), url(${backgroundDesktop}) center center no-repeat;
    @media screen and (min-width: 481px) and (max-width: 980px) {
        padding: 20px;
    }
    @media screen and (min-width: 271px) and (max-width: 480px) {
        height: 266px;
        padding: 0;
        background: url(${backgroundMobile}) center center no-repeat;
    }
    @media screen and (max-width: 270px) {
        background: linear-gradient(0deg, rgba(0,0,0,0.45), rgba(0,0,0,0.45)),url(${backgroundDesktop}) center center no-repeat;
        padding: 0;
    }
`


const CardsContainer = styled.section`
    max-width: 700px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    transition: all 200ms;
    @media screen and (min-width: 480px) and (max-width: 980px) {
        max-width: 500px;
    }
    @media screen and (max-width: 480px) {
        max-width: 370px;
        gap: 10px;
    }
`

function HomePrestations() {
    const { theme } = useTheme()

    const cardLinks = [
        {
            className:'card-1',
            key:'prestation-1',
            to:'/creation-sites-internet-caen',
            title:"SITES INTERNET",
            card: {
                title: "SITE INTERNET",
                picture: [
                    sitesLight,
                    sitesDark,
                ]
            }
        },
        {
            className:'card-2',
            key:'prestation-2',
            to:'/developpeur-application-mobile-web-caen',
            title:"APPLICATIONS",
            card: {
                title: "APPLICATION",
                picture: [
                    appLight,
                    appDark,
                ]
            }
        },
        {
            className:'card-3',
            key:'prestation-3',
            to:'/developpement-web-android',
            title:"SUR-MESURE",
            card: {
                title: "SUR-MESURE",
                picture: [
                    demandLight,
                    demandDark,
                ]
            }
        },
        {
            className:'card-4',
            key:'prestation-4',
            to:'/referencement-seo',
            title:"RÉFÉRENCEMENT",
            card: {
                title: "RÉFÉRENCEMENT",
                picture: [
                    seoLight,
                    seoDark,
                ]
            }
        }
    ]

    const prestations = [
        'Site vitrine',
        'Plateforme e-commerce',
        'Application web ou mobile',
        'Réseau social d\'entreprise',
        'Référencement SEO'
    ]



    return (

        <HelmetProvider>
            <Helmet>
                <link rel="preload" href={`${backgroundMobile}`} as="image" media="(min-width: 271px) and (max-width: 480px)" />
                <link rel="preload" href={`${backgroundDesktop}`} as="image" media="((min-width: 481px)) or ((min-width: 1px) and (max-width: 270px)) " />
            </Helmet>
            <PrestationsSection theme={theme}>
                <ContentSection>
                    <EntityTitle>
                        PRESTATIONS
                    </EntityTitle>
                    <StyledBar theme={theme}/>
                    <EntityText>
                        Agence web située près de Caen en Normandie, Becom'in vous accompagne dans le développement et la réalisation de votre projet numérique ou digital :
                    </EntityText>
                    <PrestationsList>
                        {prestations && prestations.map((prestation) => (
                            <PrestationExample theme={theme} key={prestation}>{prestation}</PrestationExample>
                        ))}
                    </PrestationsList>
                </ContentSection>

                <PicturePrestationsSection>
                    <CardsContainer>
                
                        {cardLinks && cardLinks.map((link) => (
                            <Link
                                key={link.key}
                                to={link.to}
                                title={link.title}
                                className={link.className}
                                style={{textDecoration:'none'}}
                            >
                                <Card theme={theme}
                                    id={link.className} 
                                    picture={link.card.picture}
                                    title={link.card.title}
                                />
                            </Link> 
                            
                        ))}
                
                    </CardsContainer>
                </PicturePrestationsSection>
            </PrestationsSection>
        </HelmetProvider>
    )
}

export default HomePrestations

import { useState, useEffect, useContext } from 'react'
import { ThemeContext, PageTitleContext } from '../context'

export function useFetch(url) {

  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  /* 
    Return vide si aucune URL, lancement du loading
    Fetch l'URL, parse la réponse et arrête le loading quand la data est récupérée
  */
  useEffect(() => {
  
    if (!url) return
    setLoading(true)
    
    async function fetchData() {
      try {
        const response = await fetch(url)
        const data = await response.json()
        setData(data)
      } 
      catch(err) {
        setError(true)
      } 
      finally {
        setLoading(false)
      }
    }
    fetchData()

  }, [url])
  return { 
    isLoading, data, error
  }
}

export function useTheme() {
  const { theme, toggleTheme } = useContext(ThemeContext)
  return { theme, toggleTheme }
}


export function usePageTitleContext() {
  const { title, setTitle } = useContext(PageTitleContext)
  return { title, setTitle }
}
import { createGlobalStyle } from 'styled-components'
import { useTheme } from '../hooks'

const StyledGlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Impact";
    src: local("Impact"),
    url("../../fonts/Impact/ImpactLTStd.woff2") format("woff2");
    font-weight: bold;
  }

  
    p, a, ul, li, span, legend, label, button {
      font-family: Segoe UI, Segoe, Roboto, sans-serif;
    }
    h1, h2, h3, h4, h5, h6 {
      font-family: "Impact"
    }
    a {
      text-decoration: none;
    }
    body {
        background: ${(props) =>
          props.isDarkMode ? `#ffffff` : `none` };
        margin: 0;
        font-synthesis: none;
    }
    h1, h2 {
      font-weight: 800;
    }
    ol, ul {
      padding-left: 10px;
    }
    h2, h3, h4 {
      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
    #root {
      margin: auto;
    }
    
    
    @media screen and (max-width: 320px) {
      p, li {
        font-size: 15px;
      }
      .form-control, span {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    
    
    /* Keyframe for Cards progressive appearance*/ 
    @keyframes apparition-prog {
        0% {
          opacity: 0;
          transform: translateY(20px); 
        }
        25% {
          opacity: 0.12;
          transform: translateY(15px); 
        }
        50% {
          opacity: 0.3;
          transform: translateY(10px); 
        }
        75% {
          opacity: 0.5;
          transform: translateY(5px); 
        }
        100% {
          opacity: 1;
          transform: translateY(0px); 
        }
    }
    
    /* Cards animation on desktop -> 1-4-3-2*/
    @media screen and (min-width: 810px) {
      .card-1 {
          animation: apparition-prog 400ms linear 400ms both;
      }
      .card-4 {
          animation: apparition-prog 400ms linear 700ms both;
      }
      .card-3 {
          animation: apparition-prog 400ms linear 1000ms both;
      }
      .card-2 {
          animation: apparition-prog 400ms linear 1300ms both;
      }
    }
    
    /* Cards animation on tablets */
    @media screen and (max-width: 810px) {
      .card-1 {
        animation: apparition-prog 300ms linear 200ms both;
      }
      .card-2 {
          animation: apparition-prog 300ms linear 500ms both;
      }
      .card-3 {
          animation: apparition-prog 300ms linear 800ms both;
      }
      .card-4 {
          animation: apparition-prog 300ms linear 1100ms both;
      }
    }

    /* Cards animation on mobiles */
    @media screen and (max-width: 480px) {
      .card-1, .card-2, .card-3, .card-4 {
        animation: none;
      }
    }
    
    .email, .phone-number {
      font-weight: bold;
      margin-top: 30px;
    }
    
    label, legend {
      margin: 10px 0 10px 5px;
      font-weight: 600;
    }
    
    input {
      margin-top: 5px;
    }
    
    p {
      font-size: 16px;
      margin-bottom: 10px;
    }
    
    @media screen and (max-width: 480px) {
      .form-control {
        line-height: 1;
      }
    }
    
    .colorGold {
      color : gold;
    }
`

function GlobalStyle() {
  const { theme } = useTheme()

  return <StyledGlobalStyle isDarkMode={theme === 'dark'} />
}

export default GlobalStyle